<template>
  <v-container style=" margin: 10px">
    <v-dialog v-model="dialog" width="400px">
      <v-card ref="form">
        <v-card-title>
          <span class="ml-6 subtitle-1 font-weight-bold blue-grey--text"
            >Add Branch Office</span
          >
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer
            ><v-form class="px-3">
              <v-col>
                <v-row>
                  <v-text-field
                    dense
                    outlined
                    label="Name"
                    ref="name"
                    class="mx-5 px-2 required"
                    v-model="name"
                    :rules="[rules.requiredName]"
                  >
                  </v-text-field>
                  <v-text-field
                    dense
                    outlined
                    label="Account Number"
                    ref="accountNumber"
                    class="mx-5 px-2 required"
                    v-model="accountNumber"
                    :rules="[rules.requiredAccountNumber]"
                  >
                  </v-text-field>
                </v-row>
                <v-row
                  ><v-col cols="12" sm="12" md="12" lg="12"> </v-col>

                  <v-text-field
                    dense
                    outlined
                    label="Phone"
                    class="mx-5 px-2 required"
                    ref="phone"
                    v-model="phone"
                    :rules="[rules.min]"
                  >
                  </v-text-field>
                </v-row>
              </v-col>
              <v-row>
                <v-spacer />
                <v-btn
                  small
                  class="warning px-1 mt-n5 mb-4 ml-10"
                  text
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  small
                  class="success  px-3 mt-n5 mb-4 ml-5 mr-10"
                  text
                  @click.stop="add"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form></v-spacer
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="d-flex flex-row container">
      <v-col cols="12" sm="6" md="6" lg="6">
        <h3 class="font-weight-bold blue-grey--text">All Deactivated Staff</h3>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6">
        <v-card class="pa-2" style="height: 120px" outlined tile>
          <div class="createNew">
            <h5>Only Deactivated Staff can be searched.</h5>
          </div>
          <div class="container">
            <v-col cols="12" sm="6" md="12">
              <v-text-field
                label="Search Staff....."
                dense
                outlined
              ></v-text-field>
            </v-col>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      class="d-flex flex-row container"
      style="margin-top: -80px; margin-right:10px"
    >
      <v-col cols="12" sm="12" lg="12" md="12" xl="12">
        <v-toolbar
          color="transparent"
          flat
          elevation="0"
          class="mb-6 title mt-2"
          height="55px"
        >
        </v-toolbar>
        <v-alert class="mx-2 mb-4" dense v-show="alert" type="success">
          {{ message }}
        </v-alert>
        <v-data-table
          :headers="headers"
          :items="staff"
          :loading="progress"
          item-key="branch"
          :server-items-length="total"
          :options.sync="options"
          class="elevation-2 mx-1 mt-n2"
          loading-text="Loading... Please wait"
          :search="search"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-avatar color="green" style="margin-right: -80px" size="25">
                  <span class="white--text subtitle-1 ">{{
                    item.name | firstLetterFunction
                  }}</span>
                </v-avatar>
              </td>
              <td>{{ item.name }}</td>
              <td class="blue--text">{{ item.accountNumber }}</td>
              <td>{{ item.phone }}</td>

              <td class="justify-left layout px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="mx-0"
                      link
                      :to="{
                        name: 'master.branch',
                        params: { slug: item.slug, id: item._id }
                      }"
                      dark
                      v-on="on"
                    >
                      <v-icon color="black">visibility</v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="mx-0"
                      tag="a"
                      link
                      :to="{
                        name: 'master.update',
                        params: { slug: item.slug, id: item._id }
                      }"
                      dark
                      v-on="on"
                    >
                      <v-icon color="black">edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.deleted === false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="mx-0"
                      @click="deleteFunction(item)"
                      dark
                      v-on="on"
                    >
                      <v-icon color="pink" size="18">mdi-block-helper</v-icon>
                    </v-btn>
                  </template>
                  <span>Deactivate</span>
                </v-tooltip>

                <v-tooltip bottom v-else>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="mx-0"
                      @click="activateBranch(item)"
                      dark
                      v-on="on"
                    >
                      <v-icon color="green" size="23"
                        >mdi-backup-restore</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Activate</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "RemovedStaff",
  data() {
    return {
      alert: false,
      formHasErrors: false,
      rules: {
        min: value => value.length >= 10 || "Must be 10 digits",
        requiredEmail: value => !!value || "Email is required.",
        requiredValidEmail: value =>
          /^[a-z0-9+_.-]+@[a-z0-9.-]+$/.test(value) || "Invalid Email address",
        requiredName: value => !!value || "Name is required.",
        requiredAccountNumber: value => !!value || "AccountNumber is Required."
      },
      model: 1,
      message: "",
      slug: "",
      count: 4,
      dialog: false,
      progress: false,
      deleteBar: false,
      alertError: false,
      deleteMessage: "Are you sure you want to delete this item?",
      name: "",
      email: "",
      accountNumber: "",
      snackbar: false,
      phone: "",
      search: "",
      justify: "space-between",
      bar: {},
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 5
      },
      icons: [
        {
          icon: "visibility_on",
          color: "green"
        },
        {
          icon: "edit",
          color: "orange"
        },
        {
          icon: "delete",
          color: "red"
        }
      ],
      staff: []
    };
  },

  computed: {
    form() {
      return {
        name: this.name,
        phone: this.phone,
        accountNumber: this.accountNumber
      };
    },
    staffList() {
      return this.staff.filter(searchTerm => {
        return (
          searchTerm.name.toLowerCase().indexOf(this.search.toLowerCase()) !==
          -1
        );
      });
    },

    headers() {
      return [
        {
          text: "",
          value: "",
          filterable: false,
          sortable: false
        },
        {
          text: "Staff Name",
          value: "name",
          filterable: true,
          sortable: false
        },
        { text: "Phone", value: "phone", filterable: false, sortable: false },
        {
          text: "Account Number",
          value: "accountNumber",
          filterable: false,
          sortable: false
        },

        { text: "Actions", filterable: false, sortable: false }
      ];
    }
  },
  filters: {
    status(value) {
      return !value ? "Active" : "Inactive";
    },
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    }
  },

  watch: {
    options: {
      handler(value) {
        this.listStaff(value.page, value.itemsPerPage);
      },
      deep: true
    }
  },

  methods: {
    listStaff(page, itemsPerPage) {
      this.progress = true;
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.auth_token}`
        },
        url: `${process.env.VUE_APP_BASE_URL}/api/v1/staffs/with/trash?paginated=true&page=${page}&limit=${itemsPerPage}`
      })
        .then(response => {
          console.log(response);
          if (response.status === 200) {
            this.progress = false;
            this.staff = response.data.data.docs;
            this.options.page = response.data.data.page;
            this.options.itemsPerPage = parseInt(response.data.data.limit);
            this.total = response.data.data.total;
          }
        })
        .catch(err => {
          if (err.statusCode === 401) {
            this.$router.replace({ path: "/login" });
          }
        });
    },

    add() {
      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
        if (this.$refs[f].validate(true)) {
          this.progress = true;
          this.dialog = false;
        }
      });
      setTimeout(() => {
        axios({
          method: "post",
          headers: {
            authorization: `Bearer ${localStorage.auth_token}`
          },
          url: `${process.env.VUE_APP_BASE}/api/v1/staff`,
          data: {
            name: this.name,
            phone: this.phone,
            accountNumber: this.accountNumber
          }
        })
          .then(response => {
            //console.log(response);
            if (response.status === 201) {
              this.message = response.data.message;
              this.alert = true;
              setTimeout(() => {
                this.listStaff(this.options.page, this.options.itemsPerPage);
                this.alert = false;
              }, 2000);
            }
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.$router.replace({ path: "/login" });
            } else {
              this.progress = false;
              this.message =
                err.response != null ? err.response.data.message : err.message;
              this.snackbar = true;
              this.dialog = true;
            }
          });
      }, 3000);
    },

    deleteFunction(item) {
      this.deleteBar = true;
      this.bar = item;
    },

    deleteStaff() {
      this.progress = true;
      this.deleteBar = false;
      setTimeout(() => {
        axios({
          method: "delete",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`
          },
          url: `${process.env.VUE_APP_BASE_URL}/api/v1/authority-branch/${this.bar._id}`
        })
          .then(response => {
            if (response.status === 200) {
              this.alert = true;
              this.message = response.data.message;
              setTimeout(() => {
                this.listBranches(this.options.page, this.options.itemsPerPage);
                this.alert = false;
              }, 2000);
              this.progress = true;
            }
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.$router.replace({ path: "/login" });
            } else {
              //console.log(err);
            }
          });
      }, 2000);
    }
  },
  created() {
    this.listStaff(this.options.page, this.options.itemsPerPage);
  }
};
</script>

<style scoped>
.createNew {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  alignt-content: stretch;
  margin-right: 25px;
}

.box {
  height: auto;
  width: auto;
  padding: 5%;
}
</style>
